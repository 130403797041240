@import './../../../../scss/theme-bootstrap';

.product-hair-quiz-match {
  @include pie-clearfix;
  display: none;
  margin: 7px 0 0;
  padding: 16px 0 6px;
  border-top: solid 1px $color-light-gray;
  @include breakpoint($medium-up) {
    padding-top: 20px;
    padding-bottom: 0;
  }
  &__icon {
    font-size: 23px;
    margin-right: 11px;
  }
  &__text {
    float: left;
    font-size: 16px;
    line-height: 30px;
    font-family: $font--avenir-medium;
  }
  &__button {
    float: right;
    font-family: $font--avenir-medium;
    margin: 0;
    font-size: 14px;
    background: transparent;
    padding: 5px 10px 4px;
    min-width: 0;
  }
}
